import clsx from "clsx"
import { cloneElement } from "react"

type Props = {
  children: [JSX.Element, JSX.Element]
}

const ModalConfirmButtons = ({ children: [okButton, cancelButton] }: Props) => (
  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
    {cloneElement(okButton, {
      className: clsx(okButton.props.className, "w-full text-base sm:ml-3 sm:w-auto sm:text-sm")
    })}

    {cloneElement(cancelButton, {
      className: clsx(
        cancelButton.props.className,
        "mt-3 w-full text-base sm:mt-0 sm:w-auto sm:text-sm"
      )
    })}
  </div>
)

export default ModalConfirmButtons
