import clsx from "clsx"
import { HTMLAttributes, ReactNode } from "react"

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
}

const TextContainer = ({ className, children, ...attrs }: Props) => (
  <div className={clsx("px-4 sm:px-6 md:px-8", className)} {...attrs}>
    {children}
  </div>
)

export default TextContainer
