import { MenuButton } from "@headlessui/react"
import { Link, LinkProps } from "@tanstack/react-location"
import clsx from "clsx"
import {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardedRef,
  ReactNode,
  AnchorHTMLAttributes
} from "react"

type BaseProps = {
  children: ReactNode
  color:
    | "alert-info"
    | "alert-warning"
    | "alert-success"
    | "alert-danger"
    | "danger"
    | "danger-outline"
    | "ghost"
    | "primary"
    | "primary-outline"
    | "secondary"
    | "success"
    | "warning"
    | "warning-outline"
    | "white"
  size?: "normal" | "small" | "smallIcon"
  isLoading?: boolean
}

export type ButtonAsAnchor = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> & {
    type: "anchor"
    disabled?: boolean
  }
export type ButtonAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & { type: "button" | "submit" }
export type ButtonAsLink = BaseProps &
  Omit<LinkProps, "type"> & { type: "link"; disabled?: boolean }
export type ButtonAsMenuButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
    type: "menuButton"
    disabled?: boolean
  }
export type Props = ButtonAsAnchor | ButtonAsButton | ButtonAsLink | ButtonAsMenuButton

const colorToCls = {
  "alert-danger": "border-transparent text-red-700",
  "alert-info": "border-transparent text-blue-700",
  "alert-success": "border-transparent text-green-700",
  "alert-warning": "border-transparent text-orange-700",
  danger: "border-transparent text-white bg-red-600 after:border-white shadow-sm",
  "danger-outline": "border-red-300 text-red-700 bg-white after:border-red-700 shadow-sm",
  ghost: "border-transparent text-gray-600 after:border-gray-600",
  primary: "border-transparent text-white bg-blue-600 after:border-white shadow-sm",
  "primary-outline": "border-blue-300 text-blue-700 bg-white after:border-blue-700 shadow-sm",
  secondary: "border-transparent text-gray-700 bg-gray-100 after:border-gray-700 shadow-sm",
  success: "border-transparent text-white bg-green-600 after:border-white shadow-sm",
  warning: "border-transparent text-white bg-orange-600 after:border-white shadow-sm",
  "warning-outline": "border-orange-300 text-orange-700 bg-white after:border-orange-700 shadow-sm",
  white: "border-gray-300 text-gray-700 bg-white after:border-gray-700 shadow-sm"
}

const colorToHoverCls = {
  "alert-danger": "hover:bg-red-100",
  "alert-info": "hover:bg-blue-100",
  "alert-success": "hover:bg-green-100",
  "alert-warning": "hover:bg-orange-100",
  danger: "hover:bg-red-700",
  "danger-outline": "hover:bg-red-50 hover:border-red-400",
  ghost: "hover:text-gray-700 hover:bg-gray-100",
  primary: "hover:bg-blue-700",
  "primary-outline": "hover:bg-blue-50 hover:border-blue-400",
  secondary: "hover:bg-gray-200",
  success: "hover:bg-green-700",
  warning: "hover:bg-orange-700",
  "warning-outline": "hover:bg-orange-50 hover:border-orange-400",
  white: "hover:bg-gray-50 hover:border-gray-400"
}

const sizeToCls = {
  normal: clsx("px-4 py-2 text-sm"),
  small: clsx("px-2 py-1 text-xs"),
  smallIcon: clsx("h-6 w-6 text-xs leading-none")
}

const Button = (props: Props, ref: ForwardedRef<null>) => {
  const disabled = props.disabled || props.isLoading
  const cls = clsx(
    "inline-flex select-none items-center justify-center rounded-md border font-medium transition",
    sizeToCls[props.size || "normal"],
    colorToCls[props.color],
    disabled ? "cursor-not-allowed opacity-50" : colorToHoverCls[props.color],
    props.isLoading &&
      "relative text-transparent after:absolute after:block after:h-[1.25em] after:w-[1.25em] after:animate-[spin_500ms_linear_infinite] after:rounded-full after:border-2 after:!border-l-transparent after:!border-t-transparent",
    props.className
  )

  if (props.type === "anchor") {
    const { type: as, className, children, color, isLoading, size, ...attrs } = props
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <a
        ref={ref}
        className={cls}
        onClick={disabled ? e => e.preventDefault() : undefined}
        {...attrs}
      >
        {children}
      </a>
    )
  }

  if (props.type === "link") {
    const { className, children, color, isLoading, size, type, ...attrs } = props
    // Wrapping the Link in a div prevents a black border flash when the component transitions from
    // disabled to enabled.
    // Not sure why it happens, but it shows up only:
    // - when there is a CSS border color transition
    // - on react-location's Link component
    return (
      <div ref={ref} className="inline-block">
        <Link {...attrs} className={cls}>
          {children}
        </Link>
      </div>
    )
  }

  if (props.type === "menuButton") {
    const { className, children, color, isLoading, size, type, ...attrs } = props
    return (
      <MenuButton ref={ref} className={cls} disabled={disabled} {...attrs}>
        {children}
      </MenuButton>
    )
  }

  const { className, children, color, isLoading, size, ...attrs } = props
  return (
    <button ref={ref} className={cls} disabled={disabled} {...attrs}>
      {children}
    </button>
  )
}

export default forwardRef(Button)
