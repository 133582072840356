import { useMemo } from "react"
import { decimalSeparator, thousandsSeparator } from "src/helpers/formatters"

const getSearchTokens = (search: string | number | boolean) =>
  `${search}`
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .split(/\s+/)
    .filter(token => token !== "")

export const getSearchRegExps = (search: string | number | boolean) =>
  getSearchTokens(search).map(token => new RegExp(escapeRegExp(token), "gi")) // case-insensitive

export const useSearchRegExps = (search: string | number | boolean) =>
  useMemo(() => getSearchRegExps(search), [search])

const numberRegex = new RegExp(`^[\\d${decimalSeparator}${thousandsSeparator}]+$`)

export const getNumberSearchRegExps = (search: string | number | boolean) =>
  getSearchTokens(search)
    .filter(token => token.match(numberRegex))
    .map(
      token =>
        new RegExp(
          token
            .split("")
            .filter(digit => digit !== thousandsSeparator)
            .join(`[${thousandsSeparator}]*`) + `[${thousandsSeparator}]*`,
          "g"
        )
    )

export const useNumberSearchRegExps = (search: string | number | boolean) =>
  useMemo(() => getNumberSearchRegExps(search), [search])

export const usePrettyPublishingPointSearchRegExps = (search: string) =>
  useMemo(
    () =>
      search
        .trim()
        .split(/[\s/]+/)
        .filter(token => token !== "")
        .map(token => new RegExp(escapeRegExp(token), "gi")), // case-insensitive
    [search]
  )

// Copied from https://github.com/bvaughn/highlight-words-core/blob/eb170f8a78c7926b613e72733267f3243696113c/src/utils.js#L172
const escapeRegExp = (str: string) => str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&") // $& means the whole matched string

// Test RegExp ignoring diacritic signs, like accents
export const testIgnoreDiacritics = (regExp: RegExp, text = "") =>
  text
    ? !!text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .match(regExp)
    : false
