import { useDeleteQuery, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { OrganizationParams, PoolParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /pools/${poolUuid}/?org=${orgUuid} */
export const getPoolUrl = ({ orgUuid, poolUuid }: Required<PoolParams>) =>
  `/pools/${poolUuid}/?org=${orgUuid}`

/** @returns /pools/?org=${orgUuid} */
export const getPoolListUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/pools/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const usePoolList = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolListUrl(mergedParams)

  return useGetQuery<api.link.PaginatedPoolResponseList>("link", url)
}

export const usePool = (params?: PoolParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolUrl(mergedParams)

  return useGetQuery<api.link.PoolResponse>("link", url)
}

export const useUpdatePool = (params?: PoolParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolUrl(mergedParams)
  const listUrl = getPoolListUrl(mergedParams)

  type TRequest = api.link.PatchedPoolRequest
  type TResponse = api.link.PoolResponse

  return usePatchQuery<TRequest, TResponse>("link", url, [listUrl])
}

export const useDeletePool = (params?: PoolParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolUrl(mergedParams)
  const listUrl = getPoolListUrl(mergedParams)

  return useDeleteQuery("link", url, [listUrl])
}
