import { useDeleteQuery, useGetQuery, usePatchQuery, usePostQuery, useRouteParams } from "./helpers"
import { OrganizationGroupParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/user/organization_group/${organizationGroupUuid}/ */
export const getOrganizationGroupUrl = ({
  organizationGroupUuid
}: Required<OrganizationGroupParams>) => `/manage/user/organization_group/${organizationGroupUuid}/`

/** @returns /manage/user/organization_group/ */
export const getOrganizationGroupListUrl = () => "/manage/user/organization_group/"

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useOrganizationGroupList = () => {
  const url = getOrganizationGroupListUrl()

  return useGetQuery<api.user.PaginatedOrganizationGroupList>("user", url)
}

export const useOrganizationGroup = (params?: OrganizationGroupParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationGroupUrl(mergedParams)

  return useGetQuery<api.user.DetailedOrganizationGroup>("user", url)
}

export const useAddOrganizationGroup = () => {
  const listUrl = getOrganizationGroupListUrl()

  type TRequest = api.user.DetailedOrganizationGroupRequest
  type TResponse = api.user.OrganizationGroup

  return usePostQuery<TRequest, TResponse>("user", listUrl, [listUrl])
}

export const useUpdateOrganizationGroup = (params?: OrganizationGroupParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationGroupUrl(mergedParams)
  const listUrl = getOrganizationGroupListUrl()

  type TRequest = api.user.PatchedDetailedOrganizationGroupRequest
  type TResponse = api.user.DetailedOrganizationGroup

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useDeleteOrganizationGroup = (params?: OrganizationGroupParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationGroupUrl(mergedParams)
  const listUrl = getOrganizationGroupListUrl()

  return useDeleteQuery("user", url, [listUrl])
}
