import { init } from "@sentry/browser"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "src/App"
import { getEnv } from "./helpers/env"

const sentryDsn = import.meta.env.REACT_APP_SENTRY_DSN
if (typeof sentryDsn === "string") {
  init({
    beforeSend(event) {
      const exception = event.exception?.values?.[0]
      if (exception) {
        const message = exception.value || ""

        if (
          message.includes("preload") ||
          message.startsWith("Failed to fetch dynamically imported module")
        ) {
          event.fingerprint = ["network-error", "dynamic-import"]
        }
      }

      return event
    },
    dsn: sentryDsn,
    environment: getEnv(),
    release: import.meta.env.REACT_APP_SENTRY_RELEASE
  })
}

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}
