import { useLocation } from "@tanstack/react-location"
import { FC, lazy, useEffect, useState } from "react"
import { useErrorBoundary } from "react-error-boundary"
import Loader from "src/components/base/misc/Loader"
import CaptureError from "src/components/utils/CaptureError"
import { AccessDeniedError, NotFoundError } from "src/helpers/errors"
import { ApiError } from "src/services/api"

const AccessDenied = lazy(() => import("src/components/landings/AccessDenied"))
const NotFound = lazy(() => import("src/components/landings/NotFound"))

interface Props {
  error: any
}

const ErrorFallback: FC<Props> = ({ error }) => {
  const { resetBoundary } = useErrorBoundary()
  const location = useLocation()
  const { href } = location.current
  const [initialHref] = useState(href)

  useEffect(() => {
    if (href !== initialHref) {
      resetBoundary()
    }
  }, [location, href]) // eslint-disable-line react-hooks/exhaustive-deps

  // Page not found
  if (error instanceof NotFoundError) {
    return <NotFound />
  }

  // API 404
  if (error instanceof ApiError && error.status === 404) {
    return <NotFound description="Some of the content required on this page could not be found." />
  }

  // API 403 or Access denied
  if (error instanceof AccessDeniedError || (error instanceof ApiError && error.status === 403)) {
    return <AccessDenied />
  }

  // Generic error page
  return (
    <>
      <CaptureError error={error} />
      <Loader error={error} isCover />
    </>
  )
}

export default ErrorFallback
