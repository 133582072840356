import { useDeleteQuery, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { ApiKeyParams, OrganizationParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/user/api_key/?org=${orgUuid} */
export const getApiKeyListUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/user/api_key/?org=${orgUuid}`

/** @returns /manage/user/api_key/${apiKeyUuid}/?org=${orgUuid} */
export const getApiKeyUrl = ({ apiKeyUuid, orgUuid }: Required<ApiKeyParams>) =>
  `/manage/user/api_key/${apiKeyUuid}/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------s

export const useApiKeys = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getApiKeyListUrl(mergedParams)

  return useGetQuery<api.user.PaginatedAdminApiKeyList>("user", url)
}

export const useApiKey = (params?: ApiKeyParams) => {
  const mergedParams = useRouteParams(params)
  const url = getApiKeyUrl(mergedParams)

  return useGetQuery<api.user.AdminDetailedApiKey>("user", url)
}

export const useUpdateApiKey = (params?: ApiKeyParams) => {
  const mergedParams = useRouteParams(params)
  const url = getApiKeyUrl(mergedParams)
  const listUrl = getApiKeyListUrl(mergedParams)

  type TRequest = DeepPartial<api.user.AdminDetailedApiKey>
  type TResponse = api.user.AdminDetailedApiKey

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useDeleteApiKey = (params?: ApiKeyParams) => {
  const mergedParams = useRouteParams(params)
  const url = getApiKeyUrl(mergedParams)
  const listUrl = getApiKeyListUrl(mergedParams)

  return useDeleteQuery("user", url, [listUrl])
}
