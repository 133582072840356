import { useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { DataplaneParams, OrganizationParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /private/dataplanes/ */
export const getDataplaneListUrl = () => `/private/dataplanes/`

/** @returns /private/dataplanes/${dataplaneName}/ */
export const getDataplaneUrl = ({ dataplaneName }: Required<DataplaneParams>) =>
  `/private/dataplanes/${dataplaneName}/`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useDataplaneList = (params?: OrganizationParams) => {
  const url = getDataplaneListUrl()

  return useGetQuery<api.link.PaginatedDataplaneList>("link", url)
}

export const useDataplane = (params?: DataplaneParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDataplaneUrl(mergedParams)

  return useGetQuery<api.ott.Dataplane>("link", url)
}

export const useUpdateDataplane = (params?: DataplaneParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDataplaneUrl(mergedParams)
  const listDataplaneUrl = getDataplaneListUrl()

  type TRequest = DeepPartial<api.link.Dataplane>
  type TResponse = api.link.Dataplane

  return usePatchQuery<TRequest, TResponse>("link", url, [listDataplaneUrl])
}
