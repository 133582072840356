import { faBars } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu, MenuButton, Transition } from "@headlessui/react"
import { LinkProps, useLocation, useSearch } from "@tanstack/react-location"
import { useSuspenseQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { FC, Fragment, useMemo } from "react"
import PageTitle from "src/App/Main/layout/PageTitle"
import TextContainer from "src/components/base/containers/TextContainer"
import LinkWithForwardRef from "src/components/utils/LinkWithForwardRef"
import LogoutModal, { LogoutModalGenerics } from "src/components/wired/modals/LogoutModal"
import { useOperatorMe } from "src/queries/operators"
import TopSearch from "./TopSearch"

interface Props {
  onOpenSidebar: () => void
}

const TopNavBar: FC<Props> = ({ onOpenSidebar }) => {
  const { data: me } = useSuspenseQuery(useOperatorMe())
  const search = useSearch()
  const location = useLocation()
  const { pathname } = location.current

  // https://github.com/TanStack/react-location/issues/264
  const userNavigation: { link: LinkProps<LogoutModalGenerics>; name: string }[] = useMemo(
    () => [
      { link: { to: `/operators/${me.uuid}` }, name: "My profile" },
      { link: { search: { ...search, modal: "confirm_logout" }, to: pathname }, name: "Sign out" }
    ],
    [me, pathname, search]
  )

  return (
    <div className="flex h-16 w-full flex-shrink-0 bg-white shadow">
      <button
        className="border-r border-gray-200 px-4 text-gray-500 md:hidden"
        type="button"
        onClick={() => onOpenSidebar()}
      >
        <span className="sr-only">Open sidebar</span>
        <FontAwesomeIcon aria-hidden className="h-6 w-6" icon={faBars} />
      </button>
      <TextContainer className="mx-auto flex min-w-0 max-w-7xl flex-1 justify-between">
        <div className="flex min-w-0 flex-1 items-center justify-between">
          <PageTitle />
          <TopSearch className="ml-4 hidden w-40 md:block" dropdownClassName="w-80" />
        </div>
        <div className="flex items-center">
          {/* Profile dropdown */}
          <Menu as="div" className="relative ml-3">
            <div>
              <MenuButton className="flex max-w-xs items-center rounded-full bg-white text-sm  ">
                <span className="sr-only">Open user menu</span>

                {me.profilePictureUrl ? (
                  <img
                    alt="Me"
                    className="inline-block h-8 w-8 rounded-full"
                    referrerPolicy="no-referrer"
                    src={me.profilePictureUrl}
                  />
                ) : (
                  <div
                    aria-hidden
                    className={clsx(
                      "inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-700 text-center uppercase text-white",
                      !me.name && "opacity-50"
                    )}
                  >
                    {me.name?.substring(0, 1) || "?"}
                  </div>
                )}
              </MenuButton>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                {userNavigation.map(item => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <LinkWithForwardRef
                        className={clsx(
                          active && "bg-gray-100",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                        {...item.link}
                      >
                        {item.name}
                      </LinkWithForwardRef>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </TextContainer>
      <LogoutModal />
    </div>
  )
}

export default TopNavBar
