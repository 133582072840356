import { useDeleteQuery, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { OperatorParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/user/admin_users/${operatorUuid}/ */
export const getOperatorUrl = ({ operatorUuid }: Required<OperatorParams>) =>
  `/manage/user/admin_users/${operatorUuid}/`

/** @returns /manage/user/admin_users/ */
export const getOperatorListUrl = () => "/manage/user/admin_users/"

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useOperatorList = () => {
  const url = getOperatorListUrl()

  return useGetQuery<api.user.PaginatedAdminUserList>("user", url)
}

export const useOperator = (params?: OperatorParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOperatorUrl(mergedParams)

  return useGetQuery<api.user.DetailedAdminUser>("user", url)
}

export const useOperatorMe = () => {
  const url = `/manage/user/admin_users/me/`

  return useGetQuery<api.user.SelfAdmin>("user", url)
}

export const useUpdateOperator = (params?: OperatorParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOperatorUrl(mergedParams)
  const listUrl = getOperatorListUrl()

  type TRequest = api.user.PatchedDetailedAdminUserRequest
  type TResponse = api.user.DetailedAdminUser

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useDeleteOperator = (params?: OperatorParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOperatorUrl(mergedParams)
  const listUrl = getOperatorListUrl()

  return useDeleteQuery("user", url, [listUrl])
}
