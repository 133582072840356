import { useDeleteQuery, useGetQuery, usePatchQuery, usePostQuery, useRouteParams } from "./helpers"
import { DataplaneWorkflowSetListParams, DataplaneWorkflowSetParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/dataplanes/{dataplaneUuid}/workflowsets/ */
export const getWorkflowSetListUrl = ({
  dataplaneUuid
}: Required<DataplaneWorkflowSetListParams>) => `/manage/dataplanes/${dataplaneUuid}/workflowsets/`

/** @returns /manage/dataplanes/{dataplaneUuid}/workflowsets/{workflowSetUuid} */
export const getWorkflowSetUrl = ({
  dataplaneUuid,
  workflowSetUuid
}: Required<DataplaneWorkflowSetParams>) =>
  `/manage/dataplanes/${dataplaneUuid}/workflowsets/${workflowSetUuid}/`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useWorkflowSetList = (params: DataplaneWorkflowSetListParams) => {
  const url = getWorkflowSetListUrl(params)

  return useGetQuery<api.ott.PaginatedWorkflowSetList>("ott", url)
}

export const useWorkflowSet = (params?: DataplaneWorkflowSetParams) => {
  const mergedParams = useRouteParams(params)
  const url = getWorkflowSetUrl(mergedParams)

  return useGetQuery<api.ott.WorkflowSet>("ott", url)
}

export const useAddWorkflowSet = (params?: DataplaneWorkflowSetListParams) => {
  const mergedParams = useRouteParams(params)
  const url = getWorkflowSetListUrl(mergedParams)

  type TRequest = api.ott.WorkflowSetRequest
  type TResponse = api.ott.WorkflowSet

  return usePostQuery<TRequest, TResponse>("ott", url, [url])
}

export const useUpdateWorkflowSet = (params?: DataplaneWorkflowSetParams) => {
  const mergedParams = useRouteParams(params)
  const url = getWorkflowSetUrl(mergedParams)
  const listUrl = getWorkflowSetListUrl(mergedParams)

  type TRequest = DeepPartial<api.ott.WorkflowSetRequest>
  type TResponse = api.ott.WorkflowSet

  return usePatchQuery<TRequest, TResponse>("ott", url, [listUrl])
}

export const useDeleteWorkflowSet = (params?: DataplaneWorkflowSetParams) => {
  const mergedParams = useRouteParams(params)
  const url = getWorkflowSetUrl(mergedParams)
  const listUrl = getWorkflowSetListUrl(mergedParams)

  return useDeleteQuery("ott", url, [listUrl])
}
