import { IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-location"
import clsx from "clsx"
import { HTMLAttributes } from "react"
import Tag, { Props as TagProps } from "src/components/base/tags/Tag"

type Props = HTMLAttributes<HTMLDivElement> & {
  icon?: IconDefinition
  tags?: (Omit<TagProps, "children"> & { value: string })[]
  title?: string
  uuid?: string
  parent?: {
    icon: IconDefinition
    name: string
    to: string
  }
}

const Title = ({ className, icon, parent, title, uuid, tags, ...attrs }: Props) => (
  <div
    className={clsx("flex min-w-0 flex-col gap-x-4 lg:flex-row lg:items-center", className)}
    {...attrs}
  >
    {parent && (
      <Link
        className={clsx(
          "select-none overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-600",
          "transition-colors hover:text-gray-900",
          "lg:text-sm"
        )}
        to={parent.to}
      >
        <FontAwesomeIcon aria-hidden className="mr-2" icon={parent.icon} />
        {parent.name}
      </Link>
    )}
    {parent && <div className="hidden h-5 border-l border-gray-400 lg:block" />}

    <div className="flex min-w-0 items-center gap-x-3">
      <h1 className="overflow-hidden text-ellipsis whitespace-nowrap text-xl font-medium lg:text-2xl">
        {icon && <FontAwesomeIcon aria-hidden className="mr-2" icon={icon} />}
        {title || "<empty name>"}
      </h1>
      {uuid && (
        <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-400 lg:text-sm">
          {uuid}
        </div>
      )}
    </div>

    {tags && tags.length > 0 && (
      <div className="flex flex-wrap gap-2">
        {tags.map(({ value, ...attrs }) => (
          <Tag key={value} {...attrs}>
            {value}
          </Tag>
        ))}
      </div>
    )}
  </div>
)

export default Title
