import { DefaultGenerics, PartialGenerics, useMatches } from "@tanstack/react-location"
import { useMemo } from "react"

// Dirty hack to get all matched params in a single object.
// To use when the component is not called on the matched route.
export const useMatchesParams = <TGenerics extends PartialGenerics = DefaultGenerics>() => {
  const matches = useMatches<TGenerics>()
  const params = useMemo(
    () =>
      matches.reduce(
        (result, match) => ({ ...result, ...match.params }),
        {} as TGenerics["Params"]
      ),
    [matches]
  )
  return params
}
