import { useApi } from "src/hooks/api"
import { queryClient } from "./client"
import { getQueryKey, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { OrganizationParams, PoolParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /pools/?org=${orgUuid} */
export const getPoolListUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/pools/?org=${orgUuid}`

/** @returns /pools/${poolUuid}/?org=${orgUuid} */
export const getPoolPublicUrl = ({ orgUuid, poolUuid }: Required<PoolParams>) =>
  `/pools/${poolUuid}/?org=${orgUuid}`

/** @returns /manage/ott/pools/${poolUuid}/?org=${orgUuid} */
export const getPoolUrl = ({ orgUuid, poolUuid }: Required<PoolParams>) =>
  `/manage/ott/pools/${poolUuid}/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const usePoolList = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolListUrl(mergedParams)

  return useGetQuery<api.ott.PaginatedPoolList>("ott", url)
}

export const usePool = (params?: PoolParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolUrl(mergedParams)

  return useGetQuery<api.ott.PrivatePool>("ott", url)
}

export const useUpdatePool = (params?: PoolParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPoolUrl(mergedParams)
  const listUrl = getPoolListUrl(mergedParams)

  type TRequest = DeepPartial<api.ott.PrivatePoolRequest>
  type TResponse = api.ott.PrivatePool

  return usePatchQuery<TRequest, TResponse>("ott", url, [listUrl])
}

export const useDeletePool = (params?: PoolParams) => {
  const { jsonDelete } = useApi("ott")
  const mergedParams = useRouteParams(params)
  const url = getPoolUrl(mergedParams)
  const listUrl = getPoolListUrl(mergedParams)
  const publicUrl = getPoolPublicUrl(mergedParams)

  return {
    mutationFn: () => jsonDelete(`${publicUrl}&pool_uuid=${mergedParams.poolUuid}`),
    onSuccess: async () => {
      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("ott", listUrl) })

      setTimeout(() => queryClient.removeQueries({ queryKey: getQueryKey("ott", url) }), 1_000)
    }
  }
}
