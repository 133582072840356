import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useLocation } from "@tanstack/react-location"
import clsx from "clsx"
import { FC } from "react"
import { TNavItem } from "./types"

interface Props {
  item: TNavItem
}

const DesktopNavItem: FC<Props> = ({ item }) => {
  const location = useLocation()
  const { pathname } = location.current
  const basePath = item.basePath || item.link.to

  return (
    <Link
      key={item.name}
      className={clsx(
        pathname.startsWith(`${basePath}`)
          ? "bg-gray-800 text-white"
          : clsx("text-gray-400", !item.link.disabled && "hover:bg-gray-800 hover:text-white"),
        "group flex items-center rounded-md px-2 py-2 text-sm font-medium transition-colors",
        item.link.disabled && "opacity-50"
      )}
      {...item.link}
    >
      <FontAwesomeIcon aria-hidden className="mr-3 h-6 w-6 flex-shrink-0" icon={item.icon} />
      {item.name}
    </Link>
  )
}

export default DesktopNavItem
