import Highlighter, { Chunk, FindChunks } from "react-highlight-words"

type Props = {
  className?: string
  highlightClassName?: string
  searchRegExps: RegExp[]
  value?: string
}

const Searchable = ({
  className,
  highlightClassName = "underline",
  searchRegExps,
  value
}: Props) => (
  <>
    {searchRegExps.length > 0 ? (
      <Highlighter
        className={className}
        findChunks={findChunksWithRegExps}
        highlightClassName={highlightClassName}
        searchWords={searchRegExps}
        textToHighlight={value || ""}
      />
    ) : (
      <span className={className}>{value}</span>
    )}
  </>
)

/**
 * Examine text for any matches.
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * NB: copied from https://github.com/bvaughn/highlight-words-core/blob/eb170f8a78c7926b613e72733267f3243696113c/src/utils.js#L75
 * @return {start:number, end:number}[]
 */
const findChunksWithRegExps = ({ searchWords, textToHighlight }: FindChunks): Array<Chunk> => {
  const normalizedText = textToHighlight.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  return searchWords
    .filter(searchWord => searchWord) // Remove empty words
    .reduce((chunks, searchWord) => {
      if (!(searchWord instanceof RegExp)) {
        throw new Error("When using findChunksWithRegExps, searchWords must be an array of RegExp")
      }
      const regex = searchWord

      let match
      while ((match = regex.exec(normalizedText))) {
        const start = match.index
        const end = regex.lastIndex
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ end, start })
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++
        }
      }

      return chunks
    }, [] as Chunk[])
}

export default Searchable
