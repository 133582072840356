import clsx from "clsx"
import { HTMLAttributes, ReactNode } from "react"

type Props = HTMLAttributes<HTMLDivElement> & { children: ReactNode }

const ModalBody = ({ children, className, ...attrs }: Props) => (
  <div className={clsx("mt-2", className)} {...attrs}>
    {children}
  </div>
)

export default ModalBody
