import { useAuth0 } from "@auth0/auth0-react"
import { captureException } from "@sentry/browser"
import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { usePermissions } from "src/queries/permissions"

type OrgParams = { orgUuid?: string }

export const useLogout = () => {
  const { logout } = useAuth0()
  return () => {
    try {
      sessionStorage.clear()
      localStorage.clear()
    } catch (e) {
      captureException(e)
    }
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
  }
}

export const useHasPermission = (
  permission: api.user.PermissionSet["permissions"][number],
  params?: OrgParams
) => {
  return useSuspenseQuery({
    ...usePermissions(params),
    select: d => d.permissions.includes("admin") || d.permissions.includes(permission)
  }).data
}

export const useHasPermissionNoSuspense = (
  permission: api.user.PermissionSet["permissions"][number],
  enabled: boolean,
  params?: OrgParams
) => {
  return useQuery({
    ...usePermissions(params),
    enabled,
    select: d => d.permissions.includes("admin") || d.permissions.includes(permission)
  })
}
