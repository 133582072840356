import { useGetQuery, useRouteParams } from "./helpers"
import { OrganizationParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/user/permissions/?organization=${orgUuid} */
export const getPermissionListUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/user/permissions/?organization=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const usePermissions = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getPermissionListUrl(mergedParams)

  return useGetQuery<api.user.PermissionSet>("user", url)
}
