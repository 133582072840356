import { useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { DataplaneParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /private/dataplanes/ */
export const getDataplaneListUrl = () => `/private/dataplanes/`

/** @returns /private/dataplanes/${dataplaneName}/ */
export const getDataplaneUrl = ({ dataplaneName }: Required<DataplaneParams>) =>
  `/private/dataplanes/${dataplaneName}/`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useDataplaneList = () => {
  const url = getDataplaneListUrl()

  return useGetQuery<api.ott.PaginatedDataplaneList>("ott", url)
}

export const useDataplane = (params?: DataplaneParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDataplaneUrl(mergedParams)

  return useGetQuery<api.ott.Dataplane>("ott", url)
}

export const useUpdateDataplane = (params?: DataplaneParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDataplaneUrl(mergedParams)
  const listDataplaneUrl = getDataplaneListUrl()

  type TRequest = DeepPartial<api.ott.Dataplane>
  type TResponse = api.ott.Dataplane

  return usePatchQuery<TRequest, TResponse>("ott", url, [listDataplaneUrl])
}
