import { useApi } from "src/hooks/api"
import { queryClient } from "./client"
import { getQueryKey, useDeleteQuery, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { UserParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /users/?page_size=100000 */
export const getUserListUrl = () => "/users/?page_size=100000"

/** @returns /users/${userUuid}/ */
export const getUserUrl = ({ userUuid }: Required<UserParams>) => `/users/${userUuid}/`

/** @returns "/manage/user/reset_password/" */
export const getResetUserPasswordUrl = () => "/manage/user/reset_password/"

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useUserList = () => {
  return useGetQuery<api.user.PaginatedUserList>("user", getUserListUrl())
}

export const useUser = (params?: UserParams) => {
  const mergedParams = useRouteParams(params)
  const url = getUserUrl(mergedParams)

  return useGetQuery<api.user.DetailedUser>("user", url)
}

export const useUpdateUser = (params?: UserParams) => {
  const mergedParams = useRouteParams(params)
  const url = getUserUrl(mergedParams)
  const listUrl = getUserListUrl()

  type TRequest = api.user.PatchedDetailedUserRequest
  type TResponse = api.user.DetailedUser

  return usePatchQuery<TRequest, TResponse>("user", url, [], {
    onSuccess: () =>
      // We remove users query instead of refetching it because the dataset in HUGE in production
      queryClient.removeQueries({ exact: true, queryKey: getQueryKey("user", listUrl) })
  })
}

export const useDeleteUser = (params?: UserParams) => {
  const mergedParams = useRouteParams(params)
  const url = getUserUrl(mergedParams)
  const listUrl = getUserListUrl()

  return useDeleteQuery("user", url, [listUrl])
}

export const useResetPassword = () => {
  const { jsonPost } = useApi("user")
  const url = getResetUserPasswordUrl()

  return {
    mutationFn: (data: api.user.ResetPassword) => jsonPost(url, data)
  }
}
