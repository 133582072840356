import { faBuildings, faServer } from "@fortawesome/pro-light-svg-icons"
import { faLink, faPlay } from "@fortawesome/pro-regular-svg-icons"
import { useMatchRoute } from "@tanstack/react-location"
import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { HTMLAttributes } from "react"
import { useHasPermissionNoSuspense } from "src/hooks/auth"
import { useMatchesParams } from "src/hooks/router"
import { useApiKey } from "src/queries/apiKeys"
import { useDataplane as useLinkDataplane } from "src/queries/linkDataplane"
import { usePool as useLinkPool } from "src/queries/linkPools"
import { useOperator, useOperatorMe } from "src/queries/operators"
import { useOrganizationGroup } from "src/queries/organizationGroups"
import { useOrganization } from "src/queries/organizations"
import { useDataplane as usePlayDataplane, useDataplaneList } from "src/queries/playDataplane"
import { useDrmProvider } from "src/queries/playDrmProviders"
import { usePool as usePlayPool } from "src/queries/playPools"
import { useWorkflowSet } from "src/queries/playWorkflowSet"
import { useTeam } from "src/queries/teams"
import { useUser } from "src/queries/users"
import Skeleton from "./Skeleton"
import Title from "./Title"

type Props = HTMLAttributes<HTMLDivElement>

type AnyName = { name?: string }

const viewOnlyTag = { color: "blue", value: "View only", variant: "light" } as const

const PageTitle = ({ ...attrs }: Props) => {
  const matchRoute = useMatchRoute()

  if (matchRoute({ to: "/billing" })) {
    return <Title title="Billing accounts" {...attrs} />
  }

  if (matchRoute({ to: "/operators" })) {
    return <Title title="Operators" {...attrs} />
  }

  if (matchRoute({ to: "/operators/:operatorUuid" })) {
    return <OperatorTitle {...attrs} />
  }

  if (matchRoute({ to: "/organization-groups" })) {
    return <Title title="Organization groups" {...attrs} />
  }

  if (matchRoute({ to: "/organization-groups/:organizationGroupUuid" })) {
    return <OrganizationGroupTitle {...attrs} />
  }

  if (matchRoute({ to: "/" }) || matchRoute({ to: "/organizations" })) {
    return <Title title="Organizations" {...attrs} />
  }

  if (
    matchRoute({ to: "/organizations/:orgUuid" }) ||
    matchRoute({ to: "/organizations/:orgUuid/billing" }) ||
    matchRoute({ to: "/organizations/:orgUuid/link" }) ||
    matchRoute({ to: "/organizations/:orgUuid/organization" }) ||
    matchRoute({ to: "/organizations/:orgUuid/play" })
  ) {
    return <OrganizationTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:orgUuid/api-keys/:apiKeyUuid" })) {
    return <OrganizationApiKeyTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:orgUuid/billing" })) {
    return <BillingAccountTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:orgUuid/drm-providers/:drmProviderUuid" })) {
    return <PlayDrmProviderTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:orgUuid/link/pools/:poolUuid/api-keys/:apiKeyUuid" })) {
    return <PoolApiKeyTitle app="link" {...attrs} />
  }

  if (matchRoute({ fuzzy: true, to: "/organizations/:orgUuid/link/pools" })) {
    return <LinkPoolTitle {...attrs} />
  }

  if (matchRoute({ to: "/organizations/:orgUuid/play/pools/:poolUuid/api-keys/:apiKeyUuid" })) {
    return <PoolApiKeyTitle app="play" {...attrs} />
  }

  if (matchRoute({ fuzzy: true, to: "/organizations/:orgUuid/play/pools" })) {
    return <PlayPoolTitle {...attrs} />
  }

  if (matchRoute({ to: "/teams" })) {
    return <Title title="Teams" {...attrs} />
  }

  if (matchRoute({ to: "/teams/:teamUuid" })) {
    return <TeamTitle {...attrs} />
  }

  if (matchRoute({ to: "/trial-credit" })) {
    return <Title title="Trial credits" {...attrs} />
  }

  if (matchRoute({ to: "/users" })) {
    return <Title title="Users" {...attrs} />
  }

  if (matchRoute({ to: "/dataplanes/play" })) {
    return <Title title="Play dataplanes" {...attrs} />
  }

  if (matchRoute({ to: "/dataplanes/link" })) {
    return <Title title="Link dataplanes" {...attrs} />
  }

  if (
    matchRoute({ fuzzy: true, to: "/dataplanes/play/:dataplaneUuid/workflow-set/:workflowSetUuid" })
  ) {
    return <PlayDataplaneWorkflowSet {...attrs} />
  }

  if (matchRoute({ fuzzy: true, to: "/dataplanes/play/:dataplaneName" })) {
    return <PlayDataplaneTitle {...attrs} />
  }

  if (matchRoute({ fuzzy: true, to: "/dataplanes/link/:dataplaneName" })) {
    return <LinkDataplaneTitle {...attrs} />
  }

  if (matchRoute({ to: "/users/:userUuid" })) {
    return <UserTitle {...attrs} />
  }

  if (matchRoute({ to: "/cogs/*" })) {
    return <Title title="Cogs" {...attrs} />
  }

  if (matchRoute({ to: "/logs" })) {
    return <Title title="Event logs" {...attrs} />
  }

  return <Title {...attrs} />
}

export default PageTitle

const BillingAccountTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganization(), enabled: !!params.orgUuid })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.orgUuid}`
  }

  if (orgQuery.isLoading) {
    return <Skeleton />
  }

  return <Title parent={parent} title="Billing account" {...attrs} />
}

const OperatorTitle = ({ ...attrs }: Props) => {
  const { data: me } = useQuery(useOperatorMe())
  const params = useMatchesParams()
  const isMe = me?.uuid === params.operatorUuid
  const operatorQuery = useQuery({ ...useOperator(), enabled: !!params.operatorUuid })

  if (operatorQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      tags={isMe ? [{ color: "blue", value: "Me", variant: "light" }] : undefined}
      title={operatorQuery.data?.name}
      uuid={params.operatorUuid}
      {...attrs}
    />
  )
}

const OrganizationApiKeyTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganization(), enabled: !!params.orgUuid })
  const isOrgInGroupQuery = useHasPermissionNoSuspense("org:in_group", !!params.orgUuid)
  const apiKeyQuery = useQuery({ ...useApiKey(), enabled: !!params.apiKeyUuid })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.orgUuid}`
  }

  if (orgQuery.isLoading || isOrgInGroupQuery.isLoading || apiKeyQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      parent={parent}
      tags={isOrgInGroupQuery.data ? undefined : [viewOnlyTag]}
      title={apiKeyQuery.data?.name}
      uuid={params.apiKeyUuid}
      {...attrs}
    />
  )
}

const OrganizationGroupTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganizationGroup(), enabled: !!params.organizationGroupUuid })

  if (orgQuery.isLoading) {
    return <Skeleton />
  }

  return <Title title={orgQuery.data?.name} uuid={params.organizationGroupUuid} {...attrs} />
}

const OrganizationTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganization(), enabled: !!params.orgUuid })
  const isOrgInGroupQuery = useHasPermissionNoSuspense("org:in_group", !!params.orgUuid)

  if (orgQuery.isLoading || isOrgInGroupQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      tags={isOrgInGroupQuery.data ? undefined : [viewOnlyTag]}
      title={orgQuery.data?.name}
      uuid={params.orgUuid}
      {...attrs}
    />
  )
}

const LinkPoolTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganization(), enabled: !!params.orgUuid })
  const isOrgInGroupQuery = useHasPermissionNoSuspense("org:in_group", !!params.orgUuid)
  const poolQuery = useQuery({ ...useLinkPool(), enabled: !!params.poolUuid })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.orgUuid}/link`
  }

  if (orgQuery.isLoading || isOrgInGroupQuery.isLoading || poolQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      icon={faLink}
      parent={parent}
      tags={isOrgInGroupQuery.data ? undefined : [viewOnlyTag]}
      title={poolQuery.data?.name}
      uuid={params.poolUuid}
      {...attrs}
    />
  )
}

const PlayDrmProviderTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganization(), enabled: !!params.orgUuid })
  const isOrgInGroupQuery = useHasPermissionNoSuspense("org:in_group", !!params.orgUuid)
  const drmProviderQuery = useQuery({ ...useDrmProvider(), enabled: !!params.drmProviderUuid })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.orgUuid}/play`
  }

  if (orgQuery.isLoading || isOrgInGroupQuery.isLoading || drmProviderQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      parent={parent}
      tags={isOrgInGroupQuery.data ? undefined : [viewOnlyTag]}
      title={drmProviderQuery.data?.name}
      uuid={params.drmProviderUuid}
      {...attrs}
    />
  )
}

const PlayDataplaneWorkflowSet = ({ ...attrs }: Props) => {
  const { data: workflowSet } = useSuspenseQuery(useWorkflowSet())
  const { data: dataplanes } = useSuspenseQuery({
    ...useDataplaneList(),
    select: d => d.results
  })
  const dataplane = dataplanes.find(d => d.uuid === workflowSet.dataplaneUuid)

  const parent = {
    icon: faServer,
    name: dataplane?.name || workflowSet.dataplaneUuid,
    to: `/dataplanes/play/${dataplane?.name}`
  }

  return <Title title={workflowSet.name} uuid={workflowSet.uuid} {...attrs} parent={parent} />
}

const PlayDataplaneTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const { data: dataplane } = useSuspenseQuery(usePlayDataplane())
  const parent = {
    icon: faServer,
    name: "Play dataplanes",
    to: `/dataplanes/play`
  }

  return (
    <Title
      icon={faPlay}
      title={params.dataplaneName}
      uuid={dataplane.uuid}
      {...attrs}
      parent={parent}
    />
  )
}

const LinkDataplaneTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const { data: dataplane } = useSuspenseQuery(useLinkDataplane())
  const parent = {
    icon: faServer,
    name: "Link dataplanes",
    to: `/dataplanes/link`
  }

  return (
    <Title
      icon={faLink}
      title={params.dataplaneName}
      uuid={dataplane.uuid}
      {...attrs}
      parent={parent}
    />
  )
}

const PlayPoolTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const orgQuery = useQuery({ ...useOrganization(), enabled: !!params.orgUuid })
  const isOrgInGroupQuery = useHasPermissionNoSuspense("org:in_group", !!params.orgUuid)
  const poolQuery = useQuery({ ...usePlayPool(), enabled: !!params.poolUuid })
  const parent = {
    icon: faBuildings,
    name: orgQuery.data?.name || "",
    to: `/organizations/${params.orgUuid}/play`
  }

  if (orgQuery.isLoading || isOrgInGroupQuery.isLoading || poolQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      icon={faPlay}
      parent={parent}
      tags={isOrgInGroupQuery.data ? undefined : [viewOnlyTag]}
      title={poolQuery.data?.name}
      uuid={params.poolUuid}
      {...attrs}
    />
  )
}
interface PoolApiKeyTitleProps extends Props {
  app: "link" | "play"
}

const PoolApiKeyTitle = ({ app, ...attrs }: PoolApiKeyTitleProps) => {
  const params = useMatchesParams()
  const isOrgInGroupQuery = useHasPermissionNoSuspense("org:in_group", !!params.orgUuid)

  const usePool = app === "link" ? useLinkPool : usePlayPool
  const poolNameQuery = useQuery({
    ...usePool(),
    enabled: !!params.poolUuid,
    select: (d: AnyName) => d.name
  })

  const apiKeyQuery = useQuery({ ...useApiKey(), enabled: !!params.apiKeyUuid })
  const parent = {
    icon: faPlay,
    name: poolNameQuery.data || "",
    to: `/organizations/${params.orgUuid}/${app}/pools/${params.poolUuid}`
  }

  if (isOrgInGroupQuery.isLoading || poolNameQuery.isLoading || apiKeyQuery.isLoading) {
    return <Skeleton />
  }

  return (
    <Title
      parent={parent}
      tags={isOrgInGroupQuery.data ? undefined : [viewOnlyTag]}
      title={apiKeyQuery.data?.name}
      uuid={params.apiKeyUuid}
      {...attrs}
    />
  )
}

const TeamTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const teamQuery = useQuery({ ...useTeam(), enabled: !!params.teamUuid })

  if (teamQuery.isLoading) {
    return <Skeleton />
  }

  return <Title title={teamQuery.data?.name} uuid={params.teamUuid} {...attrs} />
}

const UserTitle = ({ ...attrs }: Props) => {
  const params = useMatchesParams()
  const userQuery = useQuery({ ...useUser(), enabled: !!params.userUuid })

  if (userQuery.isLoading) {
    return <Skeleton />
  }

  return <Title title={userQuery.data?.name} uuid={params.userUuid} {...attrs} />
}
