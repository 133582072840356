import { useNavigate } from "@tanstack/react-location"
import { useCallback } from "react"

export const useCloseModal = () => {
  const navigate = useNavigate()

  const closeModal = useCallback(() => {
    navigate({
      replace: true,
      search: old => ({ ...old, modal: undefined })
    })
  }, [navigate])

  return closeModal
}
