import { useApi } from "src/hooks/api"
import { queryClient } from "./client"
import { getQueryKey, useDeleteQuery, useGetQuery, useRouteParams } from "./helpers"
import { getOperatorListUrl, getOperatorUrl } from "./operators"
import { TeamMemberParams, TeamOrganizationGroupParams, TeamParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/user/teams/ */
export const getTeamListUrl = () => "/manage/user/teams/"

/** @returns /manage/user/teams/${teamUuid}/ */
export const getTeamUrl = ({ teamUuid }: Required<TeamParams>) => `/manage/user/teams/${teamUuid}/`

/** @returns /manage/user/teams/${teamUuid}/teamship/${operatorUuid}/ */
export const getTeamMemberUrl = ({ operatorUuid, teamUuid }: Required<TeamMemberParams>) =>
  `/manage/user/teams/${teamUuid}/teamship/${operatorUuid}/`

/** @returns /manage/user/teams/${team.uuid}/teamship/ */
export const getTeamMemberListUrl = ({ teamUuid }: Required<TeamParams>) =>
  `/manage/user/teams/${teamUuid}/teamship/`

/** @returns /manage/user/teams/${teamUuid}/organization_groups/${organizationGroupUuid}/ */
export const getTeamOrganizationGroupUrl = ({
  organizationGroupUuid,
  teamUuid
}: Required<TeamOrganizationGroupParams>) =>
  `/manage/user/teams/${teamUuid}/organization_groups/${organizationGroupUuid}/`

/** @returns /manage/user/teams/${teamUuid}/organization_groups/ */
export const getTeamOrganizationGroupListUrl = ({ teamUuid }: Required<TeamParams>) =>
  `/manage/user/teams/${teamUuid}/organization_groups/`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useTeamList = () => {
  const url = getTeamListUrl()

  return useGetQuery<api.user.PaginatedOperatorTeamList>("user", url)
}

export const useTeam = (params?: TeamParams) => {
  const mergedParams = useRouteParams(params)
  const url = getTeamUrl(mergedParams)

  return useGetQuery<api.user.OperatorTeam>("user", url)
}

export const useAddTeam = () => {
  const { jsonPost } = useApi("user")
  const url = getTeamListUrl()

  type TRequest = api.user.OperatorTeamRequest & { members: api.user.TeamShip[] }
  type TResponse = api.user.OperatorTeam

  return {
    mutationFn: async (data: TRequest) => {
      const { members, ...teamData } = data
      const team = await jsonPost<TResponse>(url, teamData)

      if (members) {
        await Promise.all(
          members.map(m =>
            jsonPost<api.user.TeamShip>(getTeamMemberListUrl({ teamUuid: team.uuid }), m)
          )
        )
      }

      return team
    },
    onSuccess: async (data: TResponse) => {
      const operatorListUrl = getOperatorListUrl()
      queryClient.refetchQueries({ queryKey: getQueryKey("user", operatorListUrl) })

      const teamUrl = getTeamUrl({ teamUuid: data.uuid })
      queryClient.setQueryData(getQueryKey("user", teamUrl), data)

      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", url) })
    }
  }
}

export const useUpdateTeam = (params?: TeamParams) => {
  const { jsonPatch } = useApi("user")
  const mergedParams = useRouteParams(params)
  const url = getTeamUrl(mergedParams)

  type TRequest = api.user.PatchedOperatorTeamRequest
  type TResponse = api.user.OperatorTeam

  return {
    mutationFn: (data: TRequest) => jsonPatch<TResponse>(url, data),
    onSuccess: async (data: TResponse) => {
      queryClient.setQueryData(getQueryKey("user", url), data)

      const teamList = getTeamListUrl()
      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", teamList) })
    }
  }
}

export const useDeleteTeam = (params?: TeamParams) => {
  const { jsonDelete } = useApi("user")
  const mergedParams = useRouteParams(params)
  const url = getTeamUrl(mergedParams)

  return {
    mutationFn: () => jsonDelete(url),
    onSuccess: async () => {
      setTimeout(() => queryClient.removeQueries({ queryKey: getQueryKey("user", url) }), 1_000)

      const teamList = getTeamListUrl()
      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", teamList) })
    }
  }
}

//------------------------------------------------------------------------------
// Team members
//------------------------------------------------------------------------------

export const useTeamMembers = (params?: TeamParams) => {
  const mergedParams = useRouteParams(params)
  const url = getTeamMemberListUrl(mergedParams)

  return useGetQuery<api.user.TeamShip[]>("user", url)
}

export const useAddTeamMember = (params?: TeamParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const listUrl = getTeamMemberListUrl(mergedParams)

  type TRequest = api.user.TeamShipRequest
  type TResponse = api.user.TeamShip

  return {
    mutationFn: (data: TRequest) => jsonPost<TResponse>(listUrl, data),
    onSuccess: async (data: TResponse) => {
      const teamshipUrl = `/manage/user/teams/${mergedParams.teamUuid}/teamship/${data.operatorUuid}/`
      queryClient.setQueryData(getQueryKey("user", teamshipUrl), data)

      const operatorUrl = `/manage/user/admin_users/${data.operatorUuid}/`

      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", operatorUrl) })
      ])
    }
  }
}

export const useAddTeamMembers = (params?: TeamParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const listUrl = getTeamMemberListUrl(mergedParams)

  type TRequest = api.user.TeamShipRequest[]
  type TResponse = api.user.TeamShip[]

  return {
    mutationFn: (data: TRequest) =>
      Promise.all(data.map(d => jsonPost<api.user.TeamShip>(listUrl, d))),
    onSuccess: async (data: TResponse) => {
      const operatorUrls = []

      for (const teamship of data) {
        const teamshipUrl = `/manage/user/teams/${mergedParams.teamUuid}/teamship/${teamship.operatorUuid}/`
        queryClient.setQueryData(getQueryKey("user", teamshipUrl), teamship)

        operatorUrls.push(`/manage/user/admin_users/${teamship.operatorUuid}/`)
      }

      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) }),
        ...operatorUrls.map(url =>
          queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", url) })
        )
      ])
    }
  }
}

export const useUpdateTeamMember = (params?: TeamMemberParams) => {
  const { jsonPatch } = useApi("user")
  const mergedParams = useRouteParams(params)
  const url = getTeamMemberUrl(mergedParams)
  const listUrl = getTeamMemberListUrl(mergedParams)
  const operatorUrl = getOperatorUrl(mergedParams)

  type TRequest = api.user.PatchedTeamShipUpdateRequest
  type TResponse = api.user.TeamShipUpdate

  return {
    mutationFn: (data: TRequest) => jsonPatch<TResponse>(url, data),
    onSuccess: async (data: TResponse) => {
      queryClient.setQueryData(getQueryKey("user", url), data)

      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", operatorUrl) })
      ])
    }
  }
}

export const useDeleteTeamMember = (params?: TeamMemberParams) => {
  const { jsonDelete } = useApi("user")
  const mergedParams = useRouteParams(params)
  const url = getTeamMemberUrl(mergedParams)
  const listUrl = getTeamMemberListUrl(mergedParams)
  const operatorUrl = getOperatorUrl(mergedParams)

  return {
    mutationFn: () => jsonDelete(url),
    onSuccess: async () => {
      setTimeout(() => queryClient.removeQueries({ queryKey: getQueryKey("user", url) }), 1_000)

      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", operatorUrl) })
      ])
    }
  }
}

//------------------------------------------------------------------------------
// Team organization groups
//------------------------------------------------------------------------------

export const useTeamOrganizationGroups = (params?: TeamParams) => {
  const mergedParams = useRouteParams(params)
  const url = getTeamOrganizationGroupListUrl(mergedParams)

  return useGetQuery<api.user.TeamOrganizationGroup[]>("user", url)
}

export const useAddTeamOrganizationGroups = (params?: TeamParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const listUrl = getTeamOrganizationGroupListUrl(mergedParams)

  type TRequest = api.user.TeamOrganizationGroupRequest[]
  type TResponse = api.user.TeamOrganizationGroup[]

  return {
    mutationFn: (data: TRequest) =>
      Promise.all(data.map(d => jsonPost<api.user.TeamOrganizationGroup>(listUrl, d))),
    onSuccess: async (data: TResponse) => {
      for (const group of data) {
        const groupUrl = getTeamOrganizationGroupUrl({
          organizationGroupUuid: group.ogreUuid,
          teamUuid: mergedParams.teamUuid
        })
        queryClient.setQueryData(getQueryKey("user", groupUrl), data)
      }

      await queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", listUrl) })
    }
  }
}

export const useDeleteTeamOrganizationGroup = (params?: TeamOrganizationGroupParams) => {
  const mergedParams = useRouteParams(params)
  const url = getTeamOrganizationGroupUrl(mergedParams)
  const listUrl = getTeamOrganizationGroupListUrl(mergedParams)

  return useDeleteQuery("user", url, [listUrl])
}
