import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { HTMLAttributes } from "react"

export type Props = HTMLAttributes<HTMLSpanElement> & {
  align?: "baseline" | "middle" | "top"
  removeBtnProps?: HTMLAttributes<HTMLDivElement>
  color: "grey" | "pink" | "purple" | "red" | "teal" | "blue" | "orange" | "emerald"
  variant?: "light" | "default"
}

const variantToColorToCls = {
  default: {
    blue: "text-white bg-blue-600",
    emerald: "text-white bg-emerald-600",
    grey: "text-white bg-gray-600",
    orange: "text-white bg-orange-600",
    pink: "text-white bg-pink-600",
    purple: "text-white bg-purple-600",
    red: "text-white bg-red-600",
    teal: "text-white bg-teal-600"
  },
  light: {
    blue: "text-blue-800 bg-blue-200",
    emerald: "text-emerald-800 bg-emerald-200",
    grey: "text-gray-800 bg-gray-200",
    orange: "text-orange-800 bg-orange-200",
    pink: "text-pink-800 bg-pink-200",
    purple: "text-purple-800 bg-purple-200",
    red: "text-red-800 bg-red-200",
    teal: "text-teal-800 bg-teal-200"
  }
}

const variantToRemoveBtnColorToCls = {
  default: {
    blue: "text-blue-300 hover:text-white hover:bg-blue-500",
    emerald: "text-emerald-300 hover:text-white hover:bg-emerald-500",
    grey: "text-gray-300 hover:text-white hover:bg-gray-500",
    orange: "text-orange-300 hover:text-white hover:bg-orange-500",
    pink: "text-pink-300 hover:text-white hover:bg-pink-500",
    purple: "text-purple-300 hover:text-white hover:bg-purple-500",
    red: "text-red-300 hover:text-white hover:bg-red-500",
    teal: "text-teal-300 hover:text-white hover:bg-teal-500"
  },
  light: {
    blue: "text-blue-500 bg-blue-200 hover:text-blue-900 hover:bg-blue-300",
    emerald: "text-emerald-500 bg-emerald-200 hover:text-emerald-900 hover:bg-emerald-300",
    grey: "text-gray-500 bg-gray-200 hover:text-gray-900 hover:bg-gray-300",
    orange: "text-orange-500 bg-orange-200 hover:text-orange-900 hover:bg-orange-300",
    pink: "text-pink-500 bg-pink-200 hover:text-pink-900 hover:bg-pink-300",
    purple: "text-purple-500 bg-purple-200 hover:text-purple-900 hover:bg-purple-300",
    red: "text-red-500 bg-red-200 hover:text-red-900 hover:bg-red-300",
    teal: "text-teal-500 bg-teal-200 hover:text-teal-900 hover:bg-teal-300"
  }
}

const alignToCls = {
  baseline: "align-baseline",
  middle: "align-middle",
  top: "align-top"
}

const Tag = ({
  align = "middle",
  children,
  className,
  color,
  removeBtnProps,
  variant = "default"
}: Props) => {
  return (
    <span
      className={clsx(
        "inline-flex whitespace-nowrap rounded align-top text-xs font-normal",
        alignToCls[align],
        variantToColorToCls[variant][color],
        className
      )}
    >
      <span className={clsx("py-[0.1875rem]", removeBtnProps ? "pl-2 pr-1" : "px-2")}>
        {children}
      </span>
      {removeBtnProps && (
        <div
          className={clsx(
            "flex items-center rounded-br rounded-tr px-1.5 text-xs",
            variantToRemoveBtnColorToCls[variant][color]
          )}
          role="button"
          {...removeBtnProps}
        >
          <FontAwesomeIcon aria-hidden icon={faTimes} />
        </div>
      )}
    </span>
  )
}

export default Tag
