import "src/styles/index.css"
import "src/styles/toastify.css"
import { Auth0Provider } from "@auth0/auth0-react"
import { faClose } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { captureException } from "@sentry/browser"
import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import {
  QueryClientProvider,
  QueryErrorResetBoundary,
  useSuspenseQuery
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Suspense, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { ToastContainer } from "react-toastify"
import Loader from "src/components/base/misc/Loader"
import AuthenticationRequired from "src/components/utils/AuthenticationRequired"
import NoTrailingSlash from "src/components/utils/NoTrailingSlash"
import { queryClient } from "src/queries/client"
import { useRelease } from "src/queries/release"
import { disabledQuery } from "src/queries/specials"
import ErrorFallback from "./ErrorFallback"
import { routes } from "./routes"

const reactLocation = new ReactLocation()

const App = () => (
  <ErrorBoundary
    fallbackRender={({ error }) => <Loader error={error} isCover />}
    onError={error => captureException(error)}
  >
    <Suspense fallback={<Loader isCover />}>
      <Auth0Provider
        authorizationParams={{
          audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin
        }}
        cacheLocation="localstorage"
        clientId={import.meta.env.REACT_APP_AUTH0_CLIENT_ID}
        domain={import.meta.env.REACT_APP_AUTH0_DOMAIN}
        issuer={import.meta.env.REACT_APP_AUTH0_ISSUER}
        useFormData
        useRefreshTokens
        useRefreshTokensFallback
        onRedirectCallback={appState => {
          const returnTo = appState?.returnTo
          if (returnTo) reactLocation.history.push(returnTo)
        }}
      >
        <QueryClientProvider client={queryClient}>
          <CheckRelease />
          <AuthenticationRequired>
            <Router
              defaultPendingElement={<Loader isFullPage />}
              location={reactLocation}
              routes={routes}
            >
              <NoTrailingSlash />
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary FallbackComponent={ErrorFallback} onReset={reset}>
                    <Outlet />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
              <ToastContainer
                closeButton={({ closeToast }) => (
                  <button className="Toastify__toast-delete" type="button" onClick={closeToast}>
                    <FontAwesomeIcon aria-hidden icon={faClose} />
                  </button>
                )}
                closeOnClick={false}
                hideProgressBar
                icon={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="colored"
              />
            </Router>
          </AuthenticationRequired>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Auth0Provider>
    </Suspense>
  </ErrorBoundary>
)

const build = {
  date: import.meta.env.BUILD_DATE as string | undefined,
  id: import.meta.env.BUILD_ID as string | undefined
}

const CheckRelease = () => {
  const releaseQuery = useRelease()
  const { data: release } = useSuspenseQuery(build.date ? releaseQuery : disabledQuery)

  useEffect(() => {
    if (release && (release.build.id !== build.id || release.build.date !== build.date)) {
      console.info("Local build not matching remote build. Reloading…", {
        local: build,
        remote: release.build
      })
      window.location.reload()
    }
  })

  return null
}

export default App
