import { useApi } from "src/hooks/api"
import { getBillingAccountListUrl, useBillingAccount } from "./billing"
import { queryClient } from "./client"
import { getQueryKey, useDeleteQuery, useGetQuery, usePatchQuery, useRouteParams } from "./helpers"
import { OrganizationParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /organizations/${orgUuid}/?org=${orgUuid} */
export const getOrganizationUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/organizations/${orgUuid}/?org=${orgUuid}`

/** @returns /organizations/ */
export const getOrganizationListUrl = () => "/organizations/"

/** @returns /manage/user/organization/${orgUuid}/validate/?org=${orgUuid} */
export const getValidateOrganizationUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/manage/user/organization/${orgUuid}/validate/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useOrganizationList = () => {
  const url = getOrganizationListUrl()

  return useGetQuery<api.user.PaginatedOrganizationListList>("user", url)
}

export const useOrganization = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationUrl(mergedParams)

  return useGetQuery<api.user.OrganizationWithUser>("user", url)
}

export const useUpdateOrganization = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationUrl(mergedParams)
  const listUrl = getOrganizationListUrl()

  type TRequest = api.user.PatchedOrganizationWithUserRequest
  type TResponse = api.user.OrganizationWithUser

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useValidateOrganization = (params?: OrganizationParams) => {
  const { jsonPost } = useApi("user")
  const mergedParams = useRouteParams(params)
  const url = getValidateOrganizationUrl(mergedParams)

  type TRequest = api.user.OrgValidationRequest
  type TResponse = api.user.OrgValidation

  const billingAccountQueryParams = useBillingAccount()

  return {
    mutationFn: (data: TRequest) => jsonPost<TResponse>(url, data),
    onSuccess: async (data: TResponse) => {
      const accountListUrl = getBillingAccountListUrl()
      const orgUrl = getOrganizationUrl(mergedParams)
      const orgListUrl = getOrganizationListUrl()

      if (data.status === "ok") {
        // Fetch billing account to prevent flash because of suspend query waking up when billing account becomes active
        // It has to be done before refetching the organization
        await queryClient.fetchQuery(billingAccountQueryParams)
        queryClient.removeQueries({ exact: true, queryKey: getQueryKey("billing", accountListUrl) })
      }

      await Promise.all([
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", orgUrl) }),
        queryClient.refetchQueries({ exact: true, queryKey: getQueryKey("user", orgListUrl) })
      ])
    }
  }
}

export const useDeleteOrganization = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getOrganizationUrl(mergedParams)
  const listUrl = getOrganizationListUrl()
  const confirmArg = `org_check=${mergedParams.orgUuid}`

  return useDeleteQuery("user", url, [listUrl], { confirmArg })
}
