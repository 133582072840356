import { useDeleteQuery, useGetQuery, usePatchQuery, usePostQuery, useRouteParams } from "./helpers"
import { DrmProviderParams, OrganizationParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /drm_providers/${drmProviderUuid}/?org=${orgUuid}} */
export const getDrmProviderUrl = ({ drmProviderUuid, orgUuid }: Required<DrmProviderParams>) =>
  `/drm_providers/${drmProviderUuid}/?org=${orgUuid}`

/** @returns /drm_providers/?org=${orgUuid} */
export const getDrmProviderListUrl = ({ orgUuid }: Required<OrganizationParams>) =>
  `/drm_providers/?org=${orgUuid}`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useDrmProviders = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDrmProviderListUrl(mergedParams)

  return useGetQuery<api.ott.PaginatedDrmProviderList>("ott", url)
}

export const useDrmProvider = (params?: DrmProviderParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDrmProviderUrl(mergedParams)

  return useGetQuery<api.ott.DrmProvider>("ott", url)
}

export const useAddDrmProvider = (params?: OrganizationParams) => {
  const mergedParams = useRouteParams(params)
  const listUrl = getDrmProviderListUrl(mergedParams)

  type TRequest = api.ott.DrmProviderRequest
  type TResponse = api.ott.DrmProvider

  return usePostQuery<TRequest, TResponse>("ott", listUrl, [listUrl])
}

export const useUpdateDrmProvider = (params?: DrmProviderParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDrmProviderUrl(mergedParams)
  const listUrl = getDrmProviderListUrl(mergedParams)

  type TRequest = DeepPartial<api.ott.DrmProviderRequest>
  type TResponse = api.ott.DrmProvider

  return usePatchQuery<TRequest, TResponse>("ott", url, [listUrl])
}

export const useDeleteDrmProvider = (params?: DrmProviderParams) => {
  const mergedParams = useRouteParams(params)
  const url = getDrmProviderUrl(mergedParams)
  const listUrl = getDrmProviderListUrl(mergedParams)
  const confirmArg = `drm_provider_uuid=${mergedParams.drmProviderUuid}`

  return useDeleteQuery("ott", url, [listUrl], { confirmArg })
}
