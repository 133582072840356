import { captureException } from "@sentry/browser"
import { useEffect } from "react"
import { ApiError } from "src/services/api"

type Props = { error: any }

const CaptureError = ({ error }: Props) => {
  useEffect(() => {
    if (!(error instanceof ApiError)) {
      captureException(error)
    }
  }, [error])

  return null
}

export default CaptureError
