import { faRightFromBracket } from "@fortawesome/pro-regular-svg-icons"
import { MakeGenerics, useSearch } from "@tanstack/react-location"
import { useState } from "react"
import Button from "src/components/base/buttons/Button"
import Modal from "src/components/base/modals/Modal"
import ModalBody from "src/components/base/modals/ModalBody"
import ModalConfirmButtons from "src/components/base/modals/ModalConfirmButtons"
import ModalTitle from "src/components/base/modals/ModalTitle"
import { useLogout } from "src/hooks/auth"
import { useCloseModal } from "./utils/hooks"

export type LogoutModalGenerics = MakeGenerics<{ Search: { modal?: "confirm_logout" } }>

const LogoutModal = () => {
  const search = useSearch<LogoutModalGenerics>()
  const closeModal = useCloseModal()

  return (
    <Modal
      color="primary"
      icon={faRightFromBracket}
      show={search.modal === "confirm_logout"}
      onDismiss={closeModal}
    >
      <ModalContent />
    </Modal>
  )
}

// The `Modal` component renders its content only when it's open (`show=true`).
// To avoid triggering API request when modals are not displayed, the modal content is isolated
// in a separated component.

const ModalContent = () => {
  const logout = useLogout()
  const closeModal = useCloseModal()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <ModalTitle>Are you sure?</ModalTitle>
      <ModalBody className="text-sm text-gray-500">Do you really want to sign out?</ModalBody>
      <ModalConfirmButtons>
        <Button
          autoFocus
          color="primary"
          isLoading={isLoading}
          type="button"
          onClick={async () => {
            setIsLoading(true)
            try {
              logout()
            } catch (e) {
              setIsLoading(false)
            }
          }}
        >
          Yes, sign me out
        </Button>
        <Button color="white" type="button" onClick={closeModal}>
          No
        </Button>
      </ModalConfirmButtons>
    </>
  )
}

export default LogoutModal
